<template>
  <div>
    <filter-slot
      :filter="[]"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      :filter-principal="filterPrincipal"
      @onChangeCurrentPage="onChangeCurrentPage"
      @reload="$refs['refPendingList'].refresh()"
    >
      <template #table>
        <b-table
          slot="table"
          ref="refPendingList"
          small
          no-provider-filtering
          sticky-header="50vh"
          responsive="sm"
          :items="search"
          :fields="fields"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
          show-empty
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>

          <template #cell(client_name)="data">
            <span v-if="moduleId === 6">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'credit-experts-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-if="moduleId === 7">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'boost-credit-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="moduleId === 14">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'bookeeping-dashboard',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <span v-else-if="module === 20">
              <router-link
                :class="[textLink]"
                :to="{
                  name: 'connection-clients-account',
                  params: {
                    idClient: data.item.account_id,
                  },
                }"
                target="_blank"
                >{{ data.item.client_name }}</router-link
              >
            </span>
            <router-link
              target="_blank"
              v-else-if="moduleId === 8"
              class
              :to="`/taxresearch/clients/account/${data.item.account_id}`"
              >{{ data.item.client_name }}</router-link
            >
          </template>

          <template #cell(status)="data">
            <div class="pl-1">
              <status-account
                :account="data.item"
                :text="false"
              ></status-account>
            </div>
          </template>

          <template #cell(fee_amount)="data">
            <span>$ {{ data.item.fee_amount }}</span>
          </template>

          <template #cell(created_at)="data">
            <span>{{ data.item.created_at | myGlobal }}</span>
          </template>
        </b-table>
      </template>
    </filter-slot>
  </div>
</template>

<script>
import fields from "@/views/commons/components/tracking-list/views/data/fields.tracking-list";
import trackingListService from "@/views/commons/components/tracking-list/service/tracking-list.service";
import IconStatus from "@/views/commons/components/letters/components/tables/letters/IconStatus.vue";
import { mapGetters, mapState } from "vuex";
import IconStatusAccount from "@/views/commons/components/zero-payment/views/IconStatusAccount";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";

export default {
  components: {
    IconStatus,
    IconStatusAccount,
    StatusAccount,
  },
  data() {
    return {
      fields: fields.filter(
        (col) =>
          col.key !== "entry_date" &&
          col.key !== "file" &&
          col.key !== "call" &&
          col.key !== "sms" &&
          col.key !== "finished"
      ),
      isBusy: false,
      items: "",
      totalRows: 0,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      nextPage: 0,
      endPage: 0,
      noteStatus: 0,
      order: "asc",
      startPage: null,
      toPage: null,
    };
  },
  async created() {
    this.fields[6].sortable = false;
    // advisor column for connection
    if (this.moduleId == 20) {
      let index = this.fields.findIndex((field) => field.key == "advisor_name");
      this.fields.splice(index, 1, {
        key: "con_advisor_name",
        label: "Advisor",
      });
    }
  },
  computed: {
    ...mapState("BoostCreditTrackingList", [
      "S_SELECT_DATE",
      "S_SELECTED_USER",
    ]),
    ...mapGetters({
      currentUser: "auth/currentUser",
      token: "auth/token",
      G_SELECT_DATE: "BoostCreditTrackingList/G_SELECT_DATE",
    }),
    // selectDateState(){
    //     return this.G_SELECT_DATE
    // },
    currentDate: {
      get() {
        const date = new Date();
        const currentDate = {
          year: date.getFullYear(),
          month: date.getMonth() + 1,
        };
        return currentDate;
      },
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  methods: {
    async search(ctx) {
      const params = {
        text: this.filterPrincipal.model,
        year: this.S_SELECT_DATE.year,
        user: this.S_SELECTED_USER,
        month: this.S_SELECT_DATE.month.value,
        type: 3,
        page: ctx.currentPage,
        perPage: this.paginate.perPage,
      };
      // <-- For credit experts connection
      if (this.moduleId == 20) {
        params.is_connection = true;
      }
      // --<
      try {
        const data = await trackingListService.searchTrackingList(params);
        this.items = data.data.data;
        this.startPage = data.data.from;
        this.paginate.currentPage = data.data.current_page;
        this.paginate.perPage = data.data.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = data.data.last_page;
        this.totalRows = data.data.total;
        this.toPage = data.data.to;
      } catch (e) {
        console.log(e);
      }

      return this.items || [];
    },
    onChangeCurrentPage(e) {
      this.paginate.currentPage = e;
    },
  },
};
</script>

<style>
</style>
