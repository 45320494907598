var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('filter-slot',{attrs:{"filter":[],"total-rows":_vm.totalRows,"paginate":_vm.paginate,"start-page":_vm.startPage,"to-page":_vm.toPage,"filter-principal":_vm.filterPrincipal},on:{"onChangeCurrentPage":_vm.onChangeCurrentPage,"reload":function($event){return _vm.$refs['refPendingList'].refresh()}},scopedSlots:_vm._u([{key:"table",fn:function(){return [_c('b-table',{ref:"refPendingList",attrs:{"slot":"table","small":"","no-provider-filtering":"","sticky-header":"50vh","responsive":"sm","items":_vm.search,"fields":_vm.fields,"current-page":_vm.paginate.currentPage,"per-page":_vm.paginate.perPage,"busy":_vm.isBusy,"show-empty":""},on:{"update:busy":function($event){_vm.isBusy=$event}},slot:"table",scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle mr-1"}),_c('strong',[_vm._v("Loading ...")])],1)]},proxy:true},{key:"cell(client_name)",fn:function(data){return [(_vm.moduleId === 6)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'credit-experts-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):_vm._e(),(_vm.moduleId === 7)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'boost-credit-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.moduleId === 14)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'bookeeping-dashboard',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.module === 20)?_c('span',[_c('router-link',{class:[_vm.textLink],attrs:{"to":{
                name: 'connection-clients-account',
                params: {
                  idClient: data.item.account_id,
                },
              },"target":"_blank"}},[_vm._v(_vm._s(data.item.client_name))])],1):(_vm.moduleId === 8)?_c('router-link',{attrs:{"target":"_blank","to":("/taxresearch/clients/account/" + (data.item.account_id))}},[_vm._v(_vm._s(data.item.client_name))]):_vm._e()]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"pl-1"},[_c('status-account',{attrs:{"account":data.item,"text":false}})],1)]}},{key:"cell(fee_amount)",fn:function(data){return [_c('span',[_vm._v("$ "+_vm._s(data.item.fee_amount))])]}},{key:"cell(created_at)",fn:function(data){return [_c('span',[_vm._v(_vm._s(_vm._f("myGlobal")(data.item.created_at)))])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }